<template>
<div>
  <v-card style="width: 100%;">
    <v-card-title>
      <h3 class="headline mb-0">{{ $tc('label.nota_debito', 2) }}</h3>
    </v-card-title>
    <v-data-table
      class="tableNotasDebito"
      :headers="headers"
      :items="notas"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:[`item.acoes`]="{ item }">
        <v-menu bottom
          id="more_vert"
          origin="center center"
          transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="item.idApuracao" @click="detalhesApuracao(item)">
              <v-list-item-action>
                <v-icon>details</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                {{ $tc('label.detalhe', 2) }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="podeImprimirNota(item)" @click="imprimirNota(item)">
              <v-list-item-action>
                <v-icon>print</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                  {{ $t('label.imprimir') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="podeExportarNota(item)" @click="exportarNotas(item)">
              <v-list-item-action>
                <v-icon>get_app</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                  {{ $t('label.exportar_nf') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="canReenviarNotaDebito && notaComErro(item) && !item.isAcaoOutraDivisao"
              @click="modalConfirmacaoReenviar(item)">
              <v-list-item-action>
                <v-icon>replay</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                {{ $t('label.reenviar') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="canReenviarNotaDebito && notaIntegrada(item) && !item.isAcaoOutraDivisao"
              @click="modalConfirmacaoResetarEReenviar(item)">
              <v-list-item-action>
                <v-icon>replay_circle_filled</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                {{ $t('label.resetar_e_reenviar') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.cod_nota_debito`]="{ item }">
        <div style="white-space: nowrap">
          {{ item.numero }}
        </div>
      </template>
      <template v-slot:[`item.statusEntidade`]="{ item }">
        <div style="white-space: nowrap">
           {{ item.entidade }} - {{ item.statusEntidade }}
        </div>
      </template>
      <template v-slot:[`item.statusIntegracao`]="{ item }">
        <div style="white-space: nowrap" v-if="!item.idRecebimento">
          -
        </div>
        <div style="white-space: nowrap" v-else>
          {{ $tc(`status_integracao.${item.statusIntegracao}`, 1) }}
          <span v-if="notaComErro(item)">
             - {{ formataMensagemDeResposta(item.respostaIntegracao) }}
          </span>
        </div>
      </template>
      <template v-slot:[`item.idContrato`]="{ item }">
        <div style="white-space: nowrap">
          <span>{{ item.idContrato }}</span>
          <v-tooltip bottom v-if="item.indContratoVinculado">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="orange darken-1"
                dark
                align="end"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                report_problem
              </v-icon>
            </template>
            <span>{{ $t('message.nd_avulsa_apuracao_contratual', {justificativa: item.justificativaVinculoContrato}) }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.indPossuiPassoFornecedor`]="{ item }">
        {{ item.indPossuiPassoFornecedor ? $t('label.sim') : $t('label.nao') }}
      </template>
      <template v-slot:[`item.cnpj`]="{ item }">
        <div style="white-space: nowrap">
          {{ item.cnpj }}
        </div>
      </template>
      <template v-slot:[`item.data_apuracao`]="{ item }">
        <div style="white-space: nowrap">
          {{ item.dtaApuracao }}
        </div>
      </template>
      <template nowrap v-slot:[`item.valor_acao`]="{ item }">
        <div style="white-space: nowrap">
          {{ getMoney(item.valorAcao) }}
        </div>
      </template>
      <template nowrap v-slot:[`item.valor_base`]="{ item }">
        <div style="white-space: nowrap">
          {{ getMoney(item.valorBase) }}
        </div>
      </template>
      <template nowrap v-slot:[`item.recebimento`]="{ item }">
        <div style="white-space: nowrap">
          {{ getMoney(item.recebimento) }}
        </div>
      </template>
      <template v-slot:[`item.data_vencimento`]="{ item }">
        <div style="white-space: nowrap">
          {{ item.dtaVencimento }}
        </div>
      </template>
      <template v-slot:[`item.prazo_pagamento`]="{ item }">
        <div style="white-space: nowrap">
          {{ item.prazoPagamento }}
        </div>
      </template>
      <template v-slot:[`item.dataContabilizacao`]="{ item }">
        <div style="white-space: nowrap">
          {{ item.dataContabilizacao }}
        </div>
      </template>
      <template v-slot:[`item.tipoUso`]="{ item }">
        <v-btn icon v-if="mostrarModalRateio(item)"  class="mx-0" @click.native="visualizarRateioContabil(item)">
          <v-icon>visibility</v-icon>
        </v-btn>
        <div v-else style="white-space: nowrap">
          {{ item.tipoUso }}
        </div>
      </template>
    </v-data-table>
  </v-card>

  <confirm
    ref="modalConfirmacaoReenvio"
    :message="$t('message.reenviar_novamente_nota_debito')"
    @agree="reenviaNotaParaIntegracao"
    @disagree="$refs.modalConfirmacaoReenvio.close()">
  </confirm>

  <confirm
    ref="modalConfirmacaoResetEReenvio"
    :message="$t('message.reenviar_novamente_nota_debito')"
    @agree="resetaEReenviaNotaParaIntegracao"
    @disagree="$refs.modalConfirmacaoResetEReenvio.close()">
  </confirm>

  <modal-rateio-contabil
  ref="modalRateioContabil">
  </modal-rateio-contabil>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
import ModalRateioContabil from '@/produto/spa/apuracao-acao-lista/ModalRateioContabil';
import { getMoney } from '../../../common/functions/helpers';
import { buscaNotas } from '../../../common/resources/notas-debito-consulta';
import exportacao from '../../../common/functions/exportacao';
import download from '../../../common/resources/downloads';
import getBasePath from '../../../common/functions/api-resource';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';

export default {
  props: {
    exportacao: false,
  },
  data() {
    return {
      resource: this.$api.notasDebitoConsulta(this.$resource),
      integracaoResource: this.$api.notasDebitoIntegracao(this.$resource),
      notas: [],

      pagination: {
        rowsPerPage: 10,
      },
      totalPage: 0,
      lastPagination: {},
      filtros: null,
      bloquearBotaoExportacao: false,
      notaReenvio: null,
      idAcao: null,
    };
  },
  components: {
    Confirm,
    ModalRateioContabil,
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.mesmaPagina(pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = pagination;
        if (this.filtros === null) {
          return;
        }
        this.filtrar();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    isAmbienteKabum() {
      return this.chaveEmpresa === 'KABUM';
    },
    usuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
    usuarioFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    isUsuarioExternoMaster() {
      return this.usuarioLogado.indUsuarioExternoMaster;
    },
    headers() {
      const headers = [];
      headers.push({
        text: '', width: '2%', value: 'acoes', sortable: false,
      });
      headers.push({ text: this.$tc('label.numero', 1), value: 'cod_nota_debito', sortable: false });
      headers.push({
        text: this.$tc('label.status_entidade', 1), width: '6%', value: 'statusEntidade', sortable: false,
      });
      headers.push({
        text: this.$tc('label.status_integracao', 1), width: '6%', value: 'statusIntegracao', sortable: false,
      });
      headers.push({
        text: this.$tc('label.fornecedor', 1), width: '6%', value: 'fornecedor', sortable: false,
      });
      headers.push({
        text: this.$tc('label.razao_social', 1), width: '6%', value: 'desRazaoSocial', sortable: false,
      });
      headers.push({
        text: this.$tc('label.categoria', 1), width: '6%', value: 'categoria', sortable: false,
      });
      headers.push({ text: this.$tc('label.cnpj', 1), value: 'cnpj', sortable: false });
      headers.push({
        text: this.$tc('label.unidade_negocio', 1), width: '6%', value: 'canal', sortable: false,
      });
      headers.push({
        text: this.$tc('label.tipo_acao', 1), width: '6%', value: 'tipoAcao', sortable: false,
      });
      headers.push({
        text: this.$tc('label.cod_contrato', 1), width: '6%', value: 'idContrato', sortable: false,
      });
      headers.push({ text: this.$tc('label.cod_acao', 1), value: 'idAcao', sortable: false });
      headers.push({ text: this.$tc('label.cod_apuracao', 1), value: 'idApuracao', sortable: false });
      headers.push({ text: this.$tc('label.cod_recebimento', 1), value: 'idRecebimento', sortable: false });
      if (this.usuarioFuncionario) {
        headers.push({
          text: this.$tc('label.tipo_uso_integrado', 2), width: '6%', value: 'tipoUso', sortable: false,
        });
        headers.push({
          text: this.$tc('label.aprovacao_fornecedor', 2), width: '6%', value: 'indPossuiPassoFornecedor', sortable: false,
        });
      }
      headers.push({
        text: this.$tc('label.periodo_apuracao', 1), width: '6%', value: 'data_apuracao', sortable: false,
      });
      headers.push({
        text: this.$tc('label.valor_acao', 1), width: '6%', value: 'valor_acao', sortable: false,
      });
      headers.push({
        text: this.$tc('label.descricao', 1), width: '12%', value: 'descricaoAcao', sortable: false,
      });
      headers.push({
        text: this.$tc('label.tipo_pagamento', 1), width: '6%', value: 'tipoPagamento', sortable: false,
      });
      headers.push({
        text: this.$tc('label.numero_pedido', 1), width: '6%', value: 'numeroPedido', sortable: false,
      });
      headers.push({
        text: this.$tc('label.valor_base', 1), width: '6%', value: 'valor_base', sortable: false,
      });
      headers.push({
        text: this.$tc('label.recebimento', 1), width: '4%', value: 'recebimento', sortable: false,
      });
      headers.push({
        text: this.$tc('label.data_vencimento', 1), width: '4%', value: 'data_vencimento', sortable: false,
      });
      headers.push({ text: this.$tc('label.prazo_pagamento', 1), value: 'prazo_pagamento', sortable: false });
      headers.push({
        text: this.$tc('label.data_contabilizacao', 1), width: '4%', value: 'dataContabilizacao', sortable: false,
      });
      return headers;
    },
    canReenviarNotaDebito() {
      if (!this.getAllRoles) {
        return false;
      }
      return this.getAllRoles
        .filter((el) => el === 'NOTA_DEBITO_REENVIO').length > 0;
    },
    permissaoImprimirExportarND() {
      if (!this.getAllRoles) {
        return false;
      }
      return this.getAllRoles
        .filter((el) => el === 'ADMIN_NOTA_DEB').length > 0;
    },
    permissaoExportarNFSellout() {
      if (!this.getAllRoles) {
        return false;
      }
      return this.getAllRoles
        .filter((el) => el === 'VISUALIZAR_INFORMACOES_SELLOUT').length > 0;
    },
  },
  methods: {
    getMoney,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    filtrar(filtrosAplicados = null) {
      this.notas = [];
      if (filtrosAplicados) {
        this.filtros = filtrosAplicados;
        this.pagination.page = 1;
      }
      let parametros = null;
      if (this.idAcao) {
        parametros = {
          cod_acao: this.idAcao,
          numeroPagina: this.pagination.page,
          tamanhoPagina: this.pagination.itemsPerPage,
        };
      } else {
        parametros = {
          ...this.filtros,
          numeroPagina: this.pagination.page,
          tamanhoPagina: this.pagination.itemsPerPage,
        };
      }

      buscaNotas(parametros, this.$resource).then((res) => {
        const { resposta, quantidadeRegistrosPagina } = res.data;

        this.totalPage = quantidadeRegistrosPagina;
        this.notas = resposta;
      });
    },
    mostrarModalRateio(item) {
      return this.isAmbienteKabum && item.calculoBonificacao === 'VALOR_FIXO' && item.indHabilitarRateioContabil;
    },
    visualizarRateioContabil(item) {
      setTimeout(() => this.$refs.modalRateioContabil.open(item), 2E2);
    },
    formataMensagemDeResposta(respostaIntegracao) {
      if (!respostaIntegracao.startsWith('{')) {
        return respostaIntegracao;
      }
      const mensagem = JSON.parse(respostaIntegracao || '{}');
      const msgTraduzida = this.traduzMsgErroIntegracao(mensagem);

      return msgTraduzida || respostaIntegracao;
    },
    traduzMsgErroIntegracao(mensagem) {
      const { developerMessage } = mensagem;
      if (!developerMessage) {
        return 'Erro interno no servidor de integração';
      }
      if (developerMessage.startsWith('Type Of Use Not Found')) {
        return 'Tipo de uso não encontrado';
      }
      if (developerMessage.startsWith('Supplier Not Found')) {
        return 'Fornecedor não encontrado';
      }
      if (developerMessage === '[typeofuse] - must not be empty') {
        return 'Tipo de uso não pode ser vazio';
      }
      return 'Erro interno no servidor de integração';
    },
    isNotaIntegrada(nota) {
      return nota && nota.statusIntegracao === 'INTEGRADO';
    },
    podeImprimirNota(nota) {
      return this.permissaoImprimirExportarND
        || !['CANCELADO', 'REPROVADO'].includes(nota.statusEntidade);
    },
    podeExportarNota(nota) {
      return (nota.calculoBonificacao === 'SELLOUT' && this.permissaoExportarNFSellout
            && this.statusPermitidosExportarSellout(nota.statusEntidade))
        || ((!nota.calculoBonificacao || nota.calculoBonificacao !== 'SELLOUT') && this.permissaoImprimirExportarND && !!nota.idRecebimento);
    },
    notaComErroOuPendenteIntegracao(nota) {
      return nota && nota.respostaIntegracao
        && ['ERRO', 'PENDENTE_INTEGRACAO'].includes(nota.statusIntegracao);
    },
    statusPermitidosExportarSellout(status) {
      return ['APROVADO', 'AGUARDANDO_APURACAO', 'EM_ANALISE', 'AGUARDANDO_APROVACAO'].includes(status);
    },
    notaComErro(nota) {
      return nota && nota.statusIntegracao === 'ERRO';
    },
    notaIntegrada(nota) {
      return nota && nota.statusIntegracao === 'INTEGRADO';
    },
    modalConfirmacaoReenviar(nota) {
      this.notaReenvio = nota;
      this.$refs.modalConfirmacaoReenvio.open();
    },
    modalConfirmacaoResetarEReenviar(nota) {
      this.notaReenvio = nota;
      this.$refs.modalConfirmacaoResetEReenvio.open();
    },
    reenviaNotaParaIntegracao() {
      if (!this.notaReenvio) {
        return;
      }

      const param = {
        idNotaDebito: this.notaReenvio.idNotaDebito,
      };

      this.resource.alteraStatusNotaParaPendenteIntegracao(param, param)
        .then(() => {
          this.filtrar();
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    resetaEReenviaNotaParaIntegracao() {
      if (!this.notaReenvio) {
        return;
      }

      const param = {
        idNotaDebito: this.notaReenvio.idNotaDebito,
      };

      this.resource.alteraStatusNotaParaPendenteIntegracao(param, param)
        .then(() => {
          this.integracaoResource.enviaND(param, param)
            .then(() => {
              this.filtrar();
            }).catch((err) => {
              this.$error(this, err);
            });
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    detalhesApuracao(item) {
      const {
        idApuracao,
        idContrato,
        idAcao,
        numero,
      } = item;

      if (idContrato) {
        const rota = 'detalharApuracaoContratoOrigem';
        this.$router.push({
          name: rota,
          params: {
            id: idApuracao,
            idContrato,
            idAcao,
            numero,
            origem: 'NOTA_DEBITO',
            from: 'NOTA_DEBITO',
          },
        });
      } else {
        let routeName = 'detalharAcao';
        if (item.template === 'VERBA_VARIAVEL') {
          routeName = 'detalharAcaoVerbaVariavel';
        }
        this.$router.push({ name: routeName, params: { idAcao, from: 'notas-debito-consulta' } });
      }
    },
    async exportarNotas(item) {
      const {
        idApuracao,
      } = item;
      let {
        idFornecedor,
      } = item;
      const tipoNota = item.calculoBonificacao === 'SELLOUT' ? 'SELLOUT' : 'SELLIN';
      const isAgrupadoFornecedorPagador = await this.verificarAgrupamentoFornecedorPagador({ idApuracao, idFornecedor });
      if (item.idFornecedorPagadorDefinido) {
        idFornecedor = 0;
      }
      this.resource.verificarDadosExportacao({ idApuracao, idFornecedor, tipoNota })
        .then((response) => {
          if (response.body) {
            const params = {
              id_acao: Number(item.idAcao),
              id_apuracao: Number(item.idApuracao),
              status: item.statusEntidade,
              ind_tela_consulta_nd: true,
            };
            if (!isAgrupadoFornecedorPagador && !item.idFornecedorPagadorDefinido) {
              params.id_fornecedor = item.idFornecedor;
            }

            if (this.usuarioFuncionario) {
              params.is_usuario_funcionario = true;
            }

            if (tipoNota === 'SELLIN') {
              if (item.idContrato) {
                exportacao.exportar(null, 'notas_sellin_memoria_apuracao_contrato', this, params);
              } else {
                exportacao.exportar(null, 'notas_sellin_memoria_apuracao_acao', this, params);
              }
            }
            if (tipoNota === 'SELLOUT') {
              if (item.idContrato) {
                exportacao.exportar(null, 'notas_sellout_memoria_apuracao_acao_contrato', this, params);
              } else {
                exportacao.exportar(null, 'notas_sellout_memoria_apuracao_acao', this, params);
              }
            }
          } else {
            this.$toast(this.$t('message.sem_dados_exportacao'));
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    async verificarAgrupamentoFornecedorPagador(filtro) {
      const response = await this.resource.verificarAgrupamentoFornecedorPagador(filtro);
      return response.body;
    },
    imprimirNDPrevia(item) {
      const {
          idAcao,
          idApuracao,
          idFornecedor,
          calculoBonificacao,
        } = item,
        basePath = getBasePath('job', 'recebimento/relatorio'),
        resource = download(this.$http);

      if (idApuracao && idFornecedor && calculoBonificacao !== 'VALOR_FIXO') {
        resource.downloadGet(basePath, {
          param: '',
          url: `nota-debito-por-fornecedor/preview/${idAcao}/${idApuracao}/${idFornecedor}`,
        }).catch(() => {
          this.$toast('Erro ao exportar. Tente novamente.');
        });
      } else {
        resource.downloadGet(basePath, {
          param: '',
          url: `nota-debito/preview/${idAcao}`,
        }).catch(() => {
          this.$toast('Erro ao exportar. Tente novamente.');
        });
      }
    },
    imprimirNota(item) {
      const { idRecebimento, numero } = item;

      if (!idRecebimento) {
        this.imprimirNDPrevia(item);
        return;
      }

      const basePath = getBasePath('job', 'recebimento/relatorio');
      const resource = download(this.$http);

      resource.downloadGet(basePath, {
        param: `?numNota=${numero}`,
        url: `nota-debito/${idRecebimento}`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
  },
  mounted() {
    const { idAcao } = this.$route.params;
    this.idAcao = idAcao;
  },
};
</script>
<style>
  .tableNotasDebito .v-data-table__wrapper table {
    width: 250%;
  }

  @media screen and (max-width: 1025px) {
    .tableNotasDebito .v-data-table__wrapper table {
      width: 300%;
    }
  }

  .tableNotasDebito .v-data-table__wrapper table th {
    white-space: nowrap;
  }

</style>
